const BookingIcon = () => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99929 8.75C8.99929 8.86051 8.95539 8.96649 8.87725 9.04463C8.79911 9.12277 8.69313 9.16667 8.58262 9.16667H1.91595C1.80545 9.16667 1.69947 9.12277 1.62133 9.04463C1.54319 8.96649 1.49929 8.86051 1.49929 8.75V1.25C1.49929 1.13949 1.54319 1.03351 1.62133 0.955372C1.69947 0.877232 1.80545 0.833333 1.91595 0.833333H8.58262C8.69313 0.833333 8.79911 0.877232 8.87725 0.955372C8.95539 1.03351 8.99929 1.13949 8.99929 1.25V2.91667C8.99929 3.02717 9.04319 3.13315 9.12133 3.21129C9.19947 3.28943 9.30545 3.33333 9.41595 3.33333C9.52646 3.33333 9.63244 3.28943 9.71058 3.21129C9.78872 3.13315 9.83262 3.02717 9.83262 2.91667V1.25C9.83262 0.918479 9.70092 0.600537 9.46651 0.366117C9.23208 0.131696 8.91414 0 8.58262 0H1.91595C1.58443 0 1.26649 0.131696 1.03207 0.366117C0.797651 0.600537 0.665955 0.918479 0.665955 1.25V8.75C0.665955 9.08152 0.797651 9.39946 1.03207 9.63388C1.26649 9.8683 1.58443 10 1.91595 10H8.58262C8.91414 10 9.23208 9.8683 9.46651 9.63388C9.70092 9.39946 9.83262 9.08152 9.83262 8.75V7.08333C9.83262 6.97283 9.78872 6.86685 9.71058 6.7887C9.63244 6.71057 9.52646 6.66667 9.41595 6.66667C9.30545 6.66667 9.19947 6.71057 9.12133 6.7887C9.04319 6.86685 8.99929 6.97283 8.99929 7.08333V8.75Z"
        fill="#616161"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8776 5.295C13.9164 5.2563 13.9472 5.21032 13.9682 5.15969C13.9892 5.10907 14 5.05481 14 5C14 4.94519 13.9892 4.89093 13.9682 4.84031C13.9472 4.78968 13.9164 4.7437 13.8776 4.705L11.3776 2.205C11.2994 2.12676 11.1933 2.08281 11.0826 2.08281C10.972 2.08281 10.8659 2.12676 10.7876 2.205C10.7094 2.28324 10.6654 2.38935 10.6654 2.5C10.6654 2.61065 10.7094 2.71676 10.7876 2.795L12.5768 4.58333H5.24929C5.13878 4.58333 5.0328 4.62723 4.95466 4.70537C4.87652 4.78351 4.83262 4.88949 4.83262 5C4.83262 5.11051 4.87652 5.21649 4.95466 5.29463C5.0328 5.37277 5.13878 5.41667 5.24929 5.41667H12.5768L10.7876 7.205C10.7094 7.28324 10.6654 7.38935 10.6654 7.5C10.6654 7.61065 10.7094 7.71676 10.7876 7.795C10.8659 7.87324 10.972 7.91719 11.0826 7.91719C11.1933 7.91719 11.2994 7.87324 11.3776 7.795L13.8776 5.295Z"
        fill="#616161"
      />
    </svg>
  );
};

export default BookingIcon;
