import ScrollWidget from "~/components/ui/scrollWidget";

import styles from "./index.module.scss";

const HomeScrollWidget = () => {
  return (
    <div className={styles.homeScrollWidget}>
      <ScrollWidget />
    </div>
  );
};

export default HomeScrollWidget;
