const LocationIcon = () => {
  return (
    <svg
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.66595 7.5L6.16595 6.5V3.929C7.02645 3.7055 7.66596 2.929 7.66596 2C7.66596 0.897 6.76895 0 5.66595 0C4.56295 0 3.66595 0.897 3.66595 2C3.66595 2.929 4.30545 3.7055 5.16595 3.929V6.5L5.66595 7.5ZM4.66595 2C4.66595 1.4485 5.11445 1 5.66595 1C6.21745 1 6.66595 1.4485 6.66595 2C6.66595 2.5515 6.21745 3 5.66595 3C5.11445 3 4.66595 2.5515 4.66595 2Z"
        fill="#616161"
      />
      <path
        d="M7.79946 4.2815L7.53295 5.2455C8.82845 5.6035 9.66595 6.292 9.66595 7C9.66595 7.946 8.02345 9 5.66595 9C3.30845 9 1.66595 7.946 1.66595 7C1.66595 6.292 2.50345 5.6035 3.79945 5.245L3.53295 4.281C1.76445 4.77 0.665955 5.8115 0.665955 7C0.665955 8.682 2.86245 10 5.66595 10C8.46945 10 10.666 8.682 10.666 7C10.666 5.8115 9.56745 4.77 7.79946 4.2815Z"
        fill="#616161"
      />
    </svg>
  );
};

export default LocationIcon;
