import type {
  DetailedHTMLProps,
  HTMLAttributes,
  PropsWithChildren,
} from "react";
import {
  type LazyLoadImageProps,
  LazyLoadImage,
} from "react-lazy-load-image-component";

type Props = {
  src: string;
  alt: string;
  lazyLoad?: boolean;
  containerProps?: DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
  imgProps?: Omit<LazyLoadImageProps, "src" | "alt">;
  aspectRatio?: string;
};

const Img = ({
  src,
  alt,
  lazyLoad = true,
  containerProps,
  imgProps,
  children,
  aspectRatio,
}: PropsWithChildren<Props>) => {
  const normalImagePlaceholder =
    lazyLoad && typeof imgProps?.placeholder == "string"
      ? imgProps?.placeholder
      : undefined;

  return (
    <picture {...containerProps}>
      {lazyLoad ? (
        <LazyLoadImage src={src} alt={alt} {...imgProps} />
      ) : (
        <picture>
          <img
            src={src}
            alt={alt}
            {...imgProps}
            placeholder={normalImagePlaceholder}
            style={{ aspectRatio }}
          />
        </picture>
      )}
      {children}
    </picture>
  );
};

export default Img;
