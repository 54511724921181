import Img from "~/components/common/img";
import MainButton from "~/components/common/mainButton";
import AsperandIcon from "~/components/icons/asperand.icon";
import BookingIcon from "~/components/icons/booking.icon";
import InstagramIcon from "~/components/icons/instagram.icon";
import LocationIcon from "~/components/icons/location.icon";
import PhoneIcon from "~/components/icons/phone.icon";
import { useLanguage } from "~/contexts/providers/language";
import englishTranslations from "~/localization/en";
import frenchTranslations from "~/localization/fr";
import { ExtendedVenue } from "~/types/venue/venue.types";

import styles from "./index.module.scss";

interface Props {
  venues: ExtendedVenue[];
}
const HomeVenues = ({ venues }: Props) => {
  const { language } = useLanguage();

  const corpsTexts =
    language === "En"
      ? englishTranslations.homePage.corps
      : frenchTranslations.homePage.corps;

  return (
    <section className={styles.homeVenues}>
      <div
        className={[
          styles.venuesContainer,
          venues.length > 3 ? styles.fourAcross : styles.threeAcross,
        ].join(" ")}
      >
        {venues.map(
          (
            {
              name,
              image,
              street,
              email,
              googleMapLocation,
              phoneNumber,
              bookingUrl,
              website,
              instagram,
            },
            i
          ) => (
            <Img
              key={`home-venue-${i}`}
              src={image}
              alt={`${name}: ${street}`}
              containerProps={{ className: styles.venue }}
            >
              <div className={styles.addressContainer}>
                {name}
                <br />
                <br />
                {street}
              </div>
              <div className={styles.dataContainer}>
                <a href={website} target="_blank" rel="noreferrer">
                  <MainButton> {name} </MainButton>
                </a>
                <div className={styles.links}>
                  <a className={styles.link} href={instagram}>
                    <InstagramIcon />
                  </a>
                  <a className={styles.link} href={`tel:${phoneNumber}`}>
                    <PhoneIcon />
                  </a>
                  <a className={styles.link} href={`mailto:${email}`}>
                    <AsperandIcon />
                  </a>
                  <a
                    className={styles.link}
                    href={googleMapLocation}
                    target="_blank"
                    rel="noopener noreferrer"
                    referrerPolicy="no-referrer"
                  >
                    <LocationIcon />
                  </a>
                  {bookingUrl && (
                    <a href={bookingUrl} target="_blank" rel="noreferrer">
                      <MainButton theme="white">
                        {corpsTexts.bookNow} <BookingIcon />
                      </MainButton>
                    </a>
                  )}
                </div>
              </div>
            </Img>
          )
        )}
      </div>
    </section>
  );
};

export default HomeVenues;
