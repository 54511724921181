import type { GetStaticProps } from "next";

import { getAllVenuesWithLocations } from "~/api/venues/getAllVenuesWithLocations";
import HomeAbout from "~/components/home/about";
import HomeScrollWidget from "~/components/home/scrollWidget";
import HomeVenues from "~/components/home/venues";
import SEO from "~/components/SEO";
import { LocationEnum } from "~/types/location/locationEnums.types";
import { ExtendedVenue } from "~/types/venue/venue.types";

interface Props {
  venues: ExtendedVenue[];
}

const additionalVenueData = {
  CLUB: {
    name: "Silencio",
    website: "/venues/?selected=CLUB",
    bookingUrl: "mailTo:contact@silencio-club.com",
    image: "temp/images/silencio-club.jpg",
  },
  DES_PRES: {
    name: "Silencio des Prés",
    website: "/venues/?selected=DES_PRES",
    bookingUrl: "https://bookings.zenchef.com/results?rid=352396&pid=1001",
    image: "temp/images/home-venue-1.png",
  },
  IBIZA: {
    name: "El Silencio",
    website: "/venues/?selected=IBIZA",
    bookingUrl:
      "https://widget.thefork.com/fr/52eb04a2-517b-4688-b0d8-64cb4aec166c/homepage/6102e518-a682-40c0-af44-2f2ba7fb2107?origin=facebook&utm_medium=integration&utm_source=instagram",
    image: "temp/images/home-venue-2.png",
  },
  NYC: {
    name: "Silencio NYC",
    website: "/venues/?selected=NYC",
    image: "temp/images/home-venue-5.jpg",
    bookingUrl: "mailTo:contact@silencionyc.com",
  },
  HORS_LES_MURS: {
    name: "Hors les murs",
    website: "/venues/?selected=HORS_LES_MURS",
    image: "temp/images/home-venue-4.png",
    bookingUrl: "mailTo:contact@silencio-club.com",
  },
};

// Generate get static props
export const getStaticProps: GetStaticProps<Props, never> = async () => {
  const rawVenues = await getAllVenuesWithLocations();
  const venues = rawVenues
    .filter((venue) => venue.location !== LocationEnum.HORS_LES_MURS)
    .map((venue) => ({
      ...venue,
      image: additionalVenueData[venue.location].image,
      name: additionalVenueData[venue.location].name,
      website: additionalVenueData[venue.location].website,
      bookingUrl: additionalVenueData[venue.location].bookingUrl,
    }));

  return {
    props: { venues },
  };
};

const Home = ({ venues }: Props) => {
  return (
    <>
      <SEO
        title="Silencio"
        description="Le Silencio est un reflet audacieux de l’air du temps."
        nofollow={false}
        noindex={false}
      />
      <HomeScrollWidget />
      <HomeAbout />
      <HomeVenues venues={venues} />
    </>
  );
};

export default Home;
