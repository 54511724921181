import { useEffect, useState } from "react";

import MainButton from "~/components/common/mainButton";

import styles from "./index.module.scss";

type Props = {
  distance?: number;
  behavior?: ScrollBehavior;
};

const ScrollWidget = ({
  distance = typeof window !== "undefined" ? window.innerHeight * 0.8 : 1080,
  behavior = "smooth",
}: Props) => {
  const scrollDown = () => {
    if (typeof window !== "undefined")
      window.scrollTo({
        top: distance ?? window.innerHeight * 0.8,
        behavior,
      });
  };
  // if the user scrolled down, don't show the scroll widget
  const [showScrollWidget, setShowScrollWidget] = useState(true);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setShowScrollWidget(false);
    } else {
      setShowScrollWidget(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <MainButton
      className={[
        styles.button,
        showScrollWidget ? styles.show : styles.hide,
      ].join(" ")}
      onClick={scrollDown}
    >
      ↓ Scroll ↓
    </MainButton>
  );
};

export default ScrollWidget;
