const PhoneIcon = () => {
  return (
    <svg
      width="9"
      height="12"
      viewBox="0 0 9 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.7322 11.0604C2.99155 10.3164 -0.0292545 6.1065 0.719913 1.4088C0.768574 1.10157 1.05707 0.890985 1.36375 0.939558L2.83046 1.17186C3.10547 1.21542 3.30734 1.45469 3.30478 1.73335C3.2981 2.4015 3.38734 3.06715 3.56977 3.70995L3.60746 3.84235C3.63275 3.93143 3.62616 4.02655 3.58883 4.1113C3.55149 4.19605 3.48575 4.26511 3.40294 4.30657C2.8971 4.55905 2.62036 5.18439 2.89366 5.74935C3.23774 6.46068 3.70573 7.10505 4.27574 7.65229C4.72923 8.08633 5.40949 8.01638 5.80528 7.61378C5.87028 7.54761 5.95637 7.50624 6.04865 7.49683C6.14093 7.48742 6.2336 7.51057 6.31062 7.56226L6.42443 7.63838C6.97934 8.01102 7.58482 8.30169 8.22231 8.50176C8.48812 8.58543 8.6533 8.85136 8.60966 9.12692L8.37744 10.5931C8.36588 10.6661 8.34004 10.7361 8.3014 10.7991C8.26276 10.8621 8.21209 10.9169 8.15227 10.9603C8.09244 11.0037 8.02465 11.0349 7.95277 11.0521C7.88088 11.0693 7.80631 11.0722 7.73331 11.0606L7.7322 11.0604Z"
        fill="#616161"
      />
    </svg>
  );
};

export default PhoneIcon;
